
const routes = [
  {
    path: '/edokHome',
    name: 'edokHome',
    component: () => import('../../views/edok/home.vue')
  },
  
  {
    path: '/edokUpload',
    name: 'edokUpload',
    component: () => import('../../views/edok/formUpload/formupload.vue')
  },
  {
    path: '/edokOPD',
    name: 'edokOPD',
    component: () => import('../../views/edok/formUpload/dokOPD.vue')
  },
  {
    path: '/dokOPDadd',
    name: 'dokOPDadd',
    component: () => import('../../views/edok/formUpload/dokOPDadd.vue')
  },
  {
    path: '/dokOPDedit',
    name: 'dokOPDedit',
    component: () => import('../../views/edok/formUpload/dokOPDedit.vue')
  },
  {
    path: '/dokOPDdirektori',
    name: 'dokOPDdirektori',
    component: () => import('../../views/edok/formUpload/dokOPDdirektori.vue')
  },
  {
    path: '/edokLampiran',
    name: 'edokLampiran',
    component: () => import('../../views/edok/formUpload/edokLampiran.vue')
  },
  {
    path: '/direktoryADD',
    name: 'direktoryADD',
    component: () => import('../../views/edok/formUpload/direktoryADD.vue')
  },
  {
    path: '/direktoryEdit',
    name: 'direktoryEdit',
    component: () => import('../../views/edok/formUpload/direktoryEdit.vue')
  },
  {
    path: '/edokmonitoring',
    name: 'edokmonitoring',
    component: () => import('../../views/edok/monitoring/edokmonitoring.vue')
  },
  {
    path: '/edokmonitoringLampiran',
    name: 'edokmonitoringLampiran',
    component: () => import('../../views/edok/monitoring/edokmonitoringLampiran.vue')
  },
  {
    path: '/formPersetujuan',
    name: 'formPersetujuan',
    component: () => import('../../views/edok/persetujuan/formPersetujuan.vue')
  },
  {
    path: '/formPersetujuanLampiran',
    name: 'formPersetujuanLampiran',
    component: () => import('../../views/edok/persetujuan/formPersetujuanLampiran.vue')
  },
  {
    path: '/dataPublikasi',
    name: 'dataPublikasi',
    component: () => import('../../views/edok/dataPublikasi/direktory/dataPublikasi.vue')
  },
  {
    path: '/publikasiDirektory',
    name: 'publikasiDirektory',
    component: () => import('../../views/edok/dataPublikasi/direktory/publikasiDirektory.vue')
  },
  {
    path: '/direktoryAddFile',
    name: 'direktoryAddFile',
    component: () => import('../../views/edok/dataPublikasi/direktory/direktoryAddFile.vue')
  },
  {
    path: '/direktoryEditFile',
    name: 'direktoryEditFile',
    component: () => import('../../views/edok/dataPublikasi/direktory/direktoryEditFile.vue')
  },
  {
    path: '/direktoryListFile',
    name: 'direktoryListFile',
    component: () => import('../../views/edok/dataPublikasi/direktory/direktoryListFile.vue')
  },
  {
    path: '/publikasiDirektoryAdd',
    name: 'publikasiDirektoryAdd',
    component: () => import('../../views/edok/dataPublikasi/direktory/publikasiDirektoryAdd.vue')
  },
  {
    path: '/publikasiDirektoryEdit',
    name: 'publikasiDirektoryEdit',
    component: () => import('../../views/edok/dataPublikasi/direktory/publikasiDirektoryEdit.vue')
  },
  {
    path: '/file',
    name: 'file',
    component: () => import('../../views/edok/dataPublikasi/file/file.vue')
  },
  {
    path: '/fileLampiran',
    name: 'fileLampiran',
    component: () => import('../../views/edok/dataPublikasi/file/fileLampiran.vue')
  },
 
  
]



export default routes
