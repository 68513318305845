<template>
  <dir>
    <q-page-container>

      <div class="bgx_login_dashboard_jpg">

        <div >

        
        <div class="row" >
            
              
                <!-- <div class="col-12 col-md-12"  style="margin-top:100px; position: relative; padding-left:40px; padding-right:40px">
                    <img src="/img/bupati.png" style=" height:60px; object-position: 50% 50%;" >
                    <img src="/img/logo.png" style=" height:42px;" >
                    <img src="/img/logo_konsel.png"  style="height:42px; ">
                    <img src="/img/wakil.png"  style="height:60px; object-position: 50% 50%;">
                </div> -->
               

                
            
            <div style="position:absolute; top:56px; right:45%; ">
           
                <img src="/img/logo.png" style=" height:42px;" alt="">
                <img src="/img/logo_konsel.png" alt=""  style="height:42px; ">
            </div>
        </div>
    </div>
        <div style="margin-top:130px; margin-bottom:50px; position: relative; padding-left:40px; padding-right:40px">

        <div class="col-md-2" ></div>
            <div class="col-md-8 xxx" >
                <q-card class="my-card" style="background: #fff; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);    opacity:0.9; ">
                    <q-card-section >
                        <div class="row" >

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px; ">
                                <a href="https://simpeg.konaweselatankab.go.id" >
                                    <q-img src="/img/kompres/simpeg.png" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://e-dokumen.konaweselatankab.go.id">
                                    <q-img src="/img/kompres/dokumen.png" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://e-kinerja.konaweselatankab.go.id"  >
                                    <q-img src="/img/kompres/linja.png" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://ppid.konaweselatankab.go.id" >
                                    <q-img src="/img/kompres/ppid.png" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://absensi.konaweselatankab.go.id" >
                                    <q-img src="/img/kompres/absensi.png" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://sumaku.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/sumaku.jpeg" :ratio="1" no-native-menu />
                                </a>
                            </div>
                    
                        
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/berita.png" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://sipd-ri.kemendagri.go.id/">
                                    <q-img src="/img/kompres/sipdnew.png" :ratio="1" no-native-menu />
                                </a>
                            </div>
                            
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://simral.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/simralnew.png" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="javascript:void(0);" @click="routex('/statistikHome')">
                                    <q-img src="/img/kompres/statistik.png" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="http://sita.konaweselatankab.go.id/"  >
                                    <q-img src="/img/kompres/sitaTanah.png" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://jdih.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/jdih.png" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="http://anoari-manasa.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/anoaMari.png" :ratio="1" no-native-menu />
                                </a>
                            </div>
                        
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="javascript:void(0);">
                                    <q-img src="/img/kompres/aset.png" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://hibah.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/hibah.jpeg" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <!-- <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="javascript:void(0);">
                                    <q-img src="/img/kompres/firetapp.png" :ratio="1" no-native-menu />
                                </a>
                            </div> -->

                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://znt.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/znttt.png" :ratio="1" no-native-menu />
                                </a>
                            </div>
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="http://simpatidesa.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/simpati.jpeg" :ratio="1" no-native-menu />
                                </a>
                            </div>
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://e-retribusi.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/retribusi.jpeg" :ratio="1" no-native-menu />
                                </a>
                            </div>
                    
                    
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="http://sapakonsel.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/sapa.jpeg" :ratio="1" no-native-menu />
                                </a>
                            </div>
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://covid19.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/covid19.jpeg" :ratio="1" no-native-menu />
                                </a>
                            </div>
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="http://marina.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/marina.jpeg" :ratio="1" no-native-menu />
                                </a>
                            </div>
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://simcard.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/simcard.jpeg" :ratio="1" no-native-menu />
                                </a>
                            </div>
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="http://e-aurel.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/aurel.jpeg" :ratio="1" no-native-menu />
                                </a>
                            </div>
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="http://e-rida.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/e-rida-high.png" :ratio="1" no-native-menu />
                                </a>
                            </div>
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://perak.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/perak.jpeg" :ratio="1" no-native-menu/>
                                </a>
                            </div>
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://srikandi.arsip.go.id/">
                                    <q-img src="/img/kompres/srikandinew.png" :ratio="1" no-native-menu/>
                                </a>
                            </div>
                            <div class=" col-lg-2 col-md-2 col-sm-2 col-xs-4 text-center" style=" padding:10px">
                                <a href="https://simbada.konaweselatankab.go.id/">
                                    <q-img src="/img/kompres/simbadanew.png" :ratio="1" no-native-menu/>
                                </a>
                            </div>
                        </div>


                        
                            <!-- <div class=" col-lg-2 col-md-2 text-center" style=" padding:10px">
                                <a href="javascript:void(0);">
                                    <q-img src="/img/kompres/pengaduan.png" :ratio="1" no-native-menu />
                                </a>
                            </div>

                            <div class=" col-lg-2 col-md-2 text-center" style=" padding:10px">
                                <a href="javascript:void(0);">
                                    <q-img src="/img/kompres/registrasi.png" :ratio="1" no-native-menu />
                                </a>
                            </div> -->
                            
                        
                    </q-card-section>
                </q-card>
                
            </div>
        <div class="col-md-2" ></div>
   
            

            



        </div>


      </div>
    </q-page-container>

  </dir>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'login',
  components: {
    HelloWorld
  },
  data() {
    return {
      key: ''
    }
  },
  methods: {
    routex(route) {
      this.$router.push(route);
    }
  },
  mounted () {
    ;
  },
}
</script>

<style >
@media only screen and (min-width: 1140px) {
  .xxx {
   
    margin: 50px 150px;
  }
}

</style>
