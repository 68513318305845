
const routes = [
  {
    path: '/sitaHome',
    name: 'sitaHome',
    component: () => import('../../views/sita/home.vue')
  },
  {
    path: '/sitaKib',
    name: 'sitaKib',
    component: () => import('../../views/sita/kib/kib.vue')
  },
  {
    path: '/sitaKibDetil',
    name: 'sitaKibDetil',
    component: () => import('../../views/sita/kib/kibDetil.vue')
  },
  {
    path: '/sitaKibAdd',
    name: 'sitaKibAdd',
    component: () => import('../../views/sita/kib/kibAdd.vue')
  },
  {
    path: '/sitaKibEdit',
    name: 'sitaKibEdit',
    component: () => import('../../views/sita/kib/kibEdit.vue')
  },
  {
    path: '/sitaSengketa',
    name: 'sitaSengketa',
    component: () => import('../../views/sita/sengketa/sengketatanah.vue')
  },
  {
    path: '/sitaSengketaAdd',
    name: 'sitaSengketaAdd',
    component: () => import('../../views/sita/sengketa/sengketatanahadd.vue')
  },
  {
    path: '/sitaSengketaEdit',
    name: 'sitaSengketaEdit',
    component: () => import('../../views/sita/sengketa/sengketatanahedit.vue')
  },
  {
    path: '/sitaSengketaLokasi',
    name: 'sitaSengketaLokasi',
    component: () => import('../../views/sita/sengketa/sengketatanahlokasi.vue')
  },
  {
    path: '/pengadaan',
    name: 'pengadaan',
    component: () => import('../../views/sita/pengadaan/pengadaan.vue')
  },
  {
    path: '/pengadaanAdd',
    name: 'pengadaanAdd',
    component: () => import('../../views/sita/pengadaan/pengadaanadd.vue')
  },
  {
    path: '/pengadaanEdit',
    name: 'pengadaanEdit',
    component: () => import('../../views/sita/pengadaan/pengadaanedit.vue')
  },
  {
    path: '/gantirugi',
    name: 'gantirugi',
    component: () => import('../../views/sita/gantirugi/gantirugi.vue')
  },
  {
    path: '/gantirugiAdd',
    name: 'gantirugiAdd',
    component: () => import('../../views/sita/gantirugi/gantirugiadd.vue')
  },
  {
    path: '/gantirugiEdit',
    name: 'gantirugiEdit',
    component: () => import('../../views/sita/gantirugi/gantirugiedit.vue')
  },
  {
    path: '/petaTanah',
    name: 'petaTanah',
    component: () => import('../../views/sita/petaTanah/petaTanah.vue')
  },
  

]



export default routes
