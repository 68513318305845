
const routes = [
    {
      path: '/planningHome',
      name: 'planningHome',
      component: () => import('../../views/planning/home.vue')
    },

    // {
    //   path: '/rpjmdHome',
    //   name: 'rpjmdHome',
    //   component: () => import('../../views/planning/rpjmd/home.vue')
    // },
  
    // {
    //   path: '/planningrpjmd',
    //   name: 'planningrpjmd',
    //   component: () => import('../../views/planning/rpjmd/rpjmd.vue')
    // },
    // {
    //   path: '/planningrpjmdAdd',
    //   name: 'planningrpjmdAdd',
    //   component: () => import('../../views/planning/rpjmd/rpjmdAdd.vue')
    // },
    // {
    //   path: '/planningrpjmdEdit',
    //   name: 'planningrpjmdEdit',
    //   component: () => import('../../views/planning/rpjmd/rpjmdEdit.vue')
    // },
    // {
    //   path: '/permasalahan',
    //   name: 'permasalahan',
    //   component: () => import('../../views/planning/rpjmd/permasalahan/permasalahan.vue')
    // },
    // {
    //   path: '/permasalahanAdd',
    //   name: 'permasalahanAdd',
    //   component: () => import('../../views/planning/rpjmd/permasalahan/permasalahanAdd.vue')
    // },
    // {
    //   path: '/permasalahanEdit',
    //   name: 'permasalahanEdit',
    //   component: () => import('../../views/planning/rpjmd/permasalahan/permasalahanEdit.vue')
    // },
    // {
    //   path: '/isustrategis',
    //   name: 'isustrategis',
    //   component: () => import('../../views/planning/rpjmd/isustrategis/isu.vue')
    // },
    // {
    //   path: '/isustrategisAdd',
    //   name: 'isustrategisAdd',
    //   component: () => import('../../views/planning/rpjmd/isustrategis/isuAdd.vue')
    // },
    // {
    //   path: '/isustrategisEdit',
    //   name: 'isustrategisEdit',
    //   component: () => import('../../views/planning/rpjmd/isustrategis/isuEdit.vue')
    // },
    // {
    //   path: '/isustrategislist',
    //   name: 'isustrategislist',
    //   component: () => import('../../views/planning/rpjmd/isustrategis/isuList.vue')
    // },
    // {
    //   path: '/isustrategislistAdd',
    //   name: 'isustrategislistAdd',
    //   component: () => import('../../views/planning/rpjmd/isustrategis/isuListAdd.vue')
    // },
    // {
    //   path: '/tujuan',
    //   name: 'tujuan',
    //   component: () => import('../../views/planning/rpjmd/tujuan/tujuan.vue')
    // },
    // {
    //   path: '/tujuanAdd',
    //   name: 'tujuanAdd',
    //   component: () => import('../../views/planning/rpjmd/tujuan/tujuanAdd.vue')
    // },
    // {
    //   path: '/tujuanEdit',
    //   name: 'tujuanEdit',
    //   component: () => import('../../views/planning/rpjmd/tujuan/tujuanEdit.vue')
    // },
    // {
    //   path: '/visi',
    //   name: 'visi',
    //   component: () => import('../../views/planning/rpjmd/visi/visi.vue')
    // },
    // {
    //   path: '/misi',
    //   name: 'misi',
    //   component: () => import('../../views/planning/rpjmd/misi/misi.vue')
    // },
    // {
    //   path: '/sasaran',
    //   name: 'sasaran',
    //   component: () => import('../../views/planning/rpjmd/sasaran/sasaran.vue')
    // },
    // {
    //   path: '/strategi',
    //   name: 'strategi',
    //   component: () => import('../../views/planning/rpjmd/strategi/strategi.vue')
    // },
    // {
    //   path: '/indikatorkinerja',
    //   name: 'indikatorkinerja',
    //   component: () => import('../../views/planning/rpjmd/indikatorkinerja.vue')
    // },
    // {
    //   path: '/planningrenstra',
    //   name: 'planningrenstra',
    //   component: () => import('../../views/planning/renstra/renstra.vue')
    // },
    // {
    //   path: '/renstraAdd',
    //   name: 'renstraAdd',
    //   component: () => import('../../views/planning/renstra/renstraAdd.vue')
    // },
    // {
    //   path: '/renstraEdit',
    //   name: 'renstraEdit',
    //   component: () => import('../../views/planning/renstra/renstraEdit.vue')
    // },
    // {
    //   path: '/planningrkpd',
    //   name: 'planningrkpd',
    //   component: () => import('../../views/planning/rkpd/rkpd.vue')
    // },
    // {
    //   path: '/rkpdAdd',
    //   name: 'rkpdAdd',
    //   component: () => import('../../views/planning/rkpd/rkpdAdd.vue')
    // },
    // {
    //   path: '/rkpdEdit',
    //   name: 'rkpdEdit',
    //   component: () => import('../../views/planning/rkpd/rkpdEdit.vue')
    // },
    // {
    //   path: '/planningrenja',
    //   name: 'planningrenja',
    //   component: () => import('../../views/planning/renja/renjaHome.vue')
    // },
    // {
    //   path: '/setuprenja',
    //   name: 'setuprenja',
    //   component: () => import('../../views/planning/renja/setuprenja/setuprenja.vue')
    // },
    // {
    //   path: '/setuprenjaAdd',
    //   name: 'setuprenjaAdd',
    //   component: () => import('../../views/planning/renja/setuprenja/setuprenjaAdd.vue')
    // },
    // {
    //   path: '/setuprenjaEdit',
    //   name: 'setuprenjaEdit',
    //   component: () => import('../../views/planning/renja/setuprenja/setuprenjaEdit.vue')
    // },
    // {
    //   path: '/dokrenja',
    //   name: 'dokrenja',
    //   component: () => import('../../views/planning/renja/dokrenja/dokrenja.vue')
    // },
    // {
    //   path: '/dokrenjaAdd',
    //   name: 'dokrenjaAdd',
    //   component: () => import('../../views/planning/renja/dokrenja/dokrenjaAdd.vue')
    // },
    // {
    //   path: '/dokrenjaEdit',
    //   name: 'dokrenjaEdit',
    //   component: () => import('../../views/planning/renja/dokrenja/dokrenjaEdit.vue')
    // },
  
  ]
  
  
  
  export default routes
  