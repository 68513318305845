<template>
    <div class="divBottomBar">
            <div class="divBottomBarCol contain_center_full" style="padding-top:5px">
                <a href="javascript:void(0);">
                    <q-img style="width:33px; height:33px;" src="/img/bar/boy.png" no-native-menu />
                </a>
                <div class="text-center">
                    <span class="h_titleBottomBar">PROFILE</span>
                </div>
            </div>
            <div class="divBottomBarCol contain_center_full" style="padding-top:5px">
                <a href="javascript:void(0);">
                    <q-img style="width:33px; height:33px;" src="/img/bar/gear.png" no-native-menu />
                </a>
                <div class="text-center">
                    <span class="h_titleBottomBar">SETTING</span>
                </div>
            </div>
            <div class="divBottomBarCol contain_center_full">
                <div class="divBottomBarColCenter contain_center_full">
                    <a href="javascript:void(0);" @click="routex('/portal')">
                        <q-img style="width:50px; height:50px;" src="/img/bar/home.png" no-native-menu />
                    </a>
                    <div class="text-center">
                        <span class="h_titleBottomBar">HOME</span>
                    </div>
                </div>
            </div>
            <div class="divBottomBarCol contain_center_full" style="padding-top:5px">
                <a href="javascript:void(0);">
                    <q-img style="width:33px; height:33px;" src="/img/bar/bell.png" no-native-menu />
                </a>
                <div class="text-center">
                    <span class="h_titleBottomBar">NOTIFICATION</span>
                </div>
            </div>
            <div class="divBottomBarCol contain_center_full" style="padding-top:5px">
                <a href="javascript:void(0);" @click="routex('/login')">
                    <q-img style="width:33px; height:33px;" src="/img/bar/logout.png" no-native-menu />
                </a>
                <div class="text-center">
                    <span class="h_titleBottomBar">LOGOUT</span>
                </div>
            </div>
        </div>
</template>

<script>

export default {
    name: 'bottomBar',
    data() {
        return {
            key: ''
        }
    },
    methods: {
        routex(route) {
            this.$router.push(route);
        }
    },
}
</script>

<style lang="scss" scoped>

</style>