
const routes = [
  {
    path: '/anoariHome',
    name: 'anoariHome',
    component: () => import('../../views/anoari/home.vue')
  },
  {
    path: '/anoari_penandatangan',
    name: 'anoari_penandatangan',
    component: () => import('../../views/anoari/penandatangan/anoari_penandatangan.vue')
  },
  {
    path: '/anoari_penandatangan_add',
    name: 'anoari_penandatangan_add',
    component: () => import('../../views/anoari/penandatangan/anoari_penandatangan_add.vue')
  },
  {
    path: '/anoari_penandatangan_edit',
    name: 'anoari_penandatangan_edit',
    component: () => import('../../views/anoari/penandatangan/anoari_penandatangan_edit.vue')
  },
  {
    path: '/anoari_master_rekening',
    name: 'anoari_master_rekening',
    component: () => import('../../views/anoari/master_rekening/anoari_master_rekening.vue')
  },
  {
    path: '/anoari_rppad',
    name: 'anoari_rppad',
    component: () => import('../../views/anoari/rppad/anoari_rppad.vue')
  },
  {
    path: '/anoari_verifikasi_cetak',
    name: 'anoari_verifikasi_cetak',
    component: () => import('../../views/anoari/verifikasi_cetak/anoari_verifikasi_cetak.vue')
  },
   
  
]



export default routes
