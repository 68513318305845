
const routes = [
  {
    path: '/kinerjaHome',
    name: 'kinerjaHome',
    component: () => import('../../views/kinerja/home.vue')
  },

  {
    path: '/ekinerjaVerifikasi',
    name: 'ekinerjaVerifikasi',
    component: () => import('../../views/kinerja/Verifikasi/kinerjaVer.vue')
  },

  {
    path: '/ekinerjWorksheet',
    name: 'ekinerjWorksheet',
    component: () => import('../../views/kinerja/Worksheet/Worksheet.vue')
  },


]



export default routes
