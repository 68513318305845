import { createRouter, createWebHistory, createWebHashHistory  } from 'vue-router'



import mainRouter from '../router/routerApp/mainRouter'
import simpegRouter from '../router/routerApp/simpegRouter'
import kinerjaRouter from '../router/routerApp/kinerjaRouter'
import jdihRouter from '../router/routerApp/jdihRouter'
import ppidRouter from '../router/routerApp/ppidRouter'
import statistikRouter from '../router/routerApp/statistikRouter'
import sitaRouter from '../router/routerApp/sitaRouter'
import edokRouter from '../router/routerApp/edokRouter'
import statistikDinkesRouter  from '../router/routerApp/statistikDinkesRouter'
import statistikDkpRouter  from '../router/routerApp/statistikDkpRouter'
import statistikBpbdRouter  from '../router/routerApp/statistikBpbdRouter'
import planningRouter  from '../router/routerApp/planningRouter'
import absensiRouter  from '../router/routerApp/absensiRouter'
import beritaRouter from '../router/routerApp/beritaRouter'
import anoariRouter from '../router/routerApp/anoariRouter'
import statistikDisperindagRouter from '../router/routerApp/statistikDisperindagRouter'
import statistikDprdRouter from '../router/routerApp/statistikDprdRouter'
import statistikBkkbnRouter from '../router/routerApp/statistikBkkbnRouter'
import statistikDisnakerRouter from '../router/routerApp/statistikDisnakerRouter'
import statistikDpmdRouter from '../router/routerApp/statistikDpmdRouter'
import statistikSatpol from '../router/routerApp/statistikSatpol'



var allRoutes = []
allRoutes = allRoutes.concat(mainRouter, simpegRouter, kinerjaRouter, jdihRouter, ppidRouter, statistikRouter, sitaRouter, edokRouter, statistikDinkesRouter, planningRouter, absensiRouter, beritaRouter, anoariRouter, statistikDkpRouter, statistikBpbdRouter, statistikDisperindagRouter, statistikDprdRouter, statistikBkkbnRouter, statistikDisnakerRouter, statistikDpmdRouter, statistikSatpol)



const routes = allRoutes


const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
})

export default router