
const routes = [
  {
    path: '/absensiHome',
    name: 'absensiHome',
    component: () => import('../../views/absensi/absensiHome.vue')
  },

  // Laporan Harian
  {
    path: '/laporanHarian',
    name: 'laporanHarian',
    component: () => import('../../views/absensi/laporanHarian.vue')
  },

  // Laporan Custom
  {
    path: '/laporanCustom',
    name: 'laporanCustom',
    component: () => import('../../views/absensi/laporanCustom.vue')
  },

  // Pengumuman
  {
    path: '/absensiPengumuman',
    name: 'absensiPengumuman',
    component: () => import('../../views/absensi/absensiPengumuman/absensiPengumuman.vue')
  },
  {
    path: '/absensiPengumumanAdd',
    name: 'absensiPengumumanAdd',
    component: () => import('../../views/absensi/absensiPengumuman/absensiPengumumanAdd.vue')
  },
  {
    path: '/absensiPengumumanEdit',
    name: 'absensiPengumumanEdit',
    component: () => import('../../views/absensi/absensiPengumuman/absensiPengumumanEdit.vue')
  },
  {
    path: '/absensiPengumumanLampiran',
    name: 'absensiPengumumanLampiran',
    component: () => import('../../views/absensi/absensiPengumuman/absensiPengumumanLampiran.vue')
  },


  // verifikasiPermohonan

  {
    path: '/verifikasiPermohonan',
    name: 'verifikasiPermohonan',
    component: () => import('../../views/absensi/verifikasiPermohonan/verifikasiPermohonan.vue')
  },
  {
    path: '/permohonanIzin',
    name: 'permohonanIzin',
    component: () => import('../../views/absensi/verifikasiPermohonan/permohonanIzin/permohonanIzin.vue')
  },
  {
    path: '/permohonanIzinTerima',
    name: 'permohonanIzinTerima',
    component: () => import('../../views/absensi/verifikasiPermohonan/permohonanIzin/permohonanIzinTerima.vue')
  },
  {
    path: '/permohonanIzinTolak',
    name: 'permohonanIzinTolak',
    component: () => import('../../views/absensi/verifikasiPermohonan/permohonanIzin/permohonanIzinTolak.vue')
  },
  {
    path: '/permohonanAbsenDarurat',
    name: 'permohonanAbsenDarurat',
    component: () => import('../../views/absensi/verifikasiPermohonan/permohonanAbsenDarurat/permohonanAbsenDarurat.vue')
  },
  {
    path: '/permohonanAbsenDaruratTerima',
    name: 'permohonanAbsenDaruratTerima',
    component: () => import('../../views/absensi/verifikasiPermohonan/permohonanAbsenDarurat/permohonanAbsenDaruratTerima.vue')
  },
  {
    path: '/permohonanAbsenDaruratTolak',
    name: 'permohonanAbsenDaruratTolak',
    component: () => import('../../views/absensi/verifikasiPermohonan/permohonanAbsenDarurat/permohonanAbsenDaruratTolak.vue')
  },
  {
    path: '/permohonanAbsenDaruratLampiran',
    name: 'permohonanAbsenDaruratLampiran',
    component: () => import('../../views/absensi/verifikasiPermohonan/permohonanAbsenDarurat/permohonanAbsenDaruratLampiran.vue')
  },
  
  // END verifikasiPermohonan

  // Verifikasi Lokasi
  
  {
    path: '/verifikasiLokasi',
    name: 'verifikasiLokasi',
    component: () => import('../../views/absensi/verifikasiLokasi/verifikasiLokasi.vue')
  },
  {
    path: '/usulanLokasiAbsen',
    name: 'usulanLokasiAbsen',
    component: () => import('../../views/absensi/verifikasiLokasi/usulanLokasiAbsen/usulanLokasiAbsen.vue')
  },
  {
    path: '/usulanLokasiAbsenMaps',
    name: 'usulanLokasiAbsenMaps',
    component: () => import('../../views/absensi/verifikasiLokasi/usulanLokasiAbsen/usulanLokasiAbsenMaps.vue')
  },
  {
    path: '/usulanLokasiAbsenAdd',
    name: 'usulanLokasiAbsenAdd',
    component: () => import('../../views/absensi/verifikasiLokasi/usulanLokasiAbsen/usulanLokasiAbsenAdd.vue')
  },
  {
    path: '/usulanLokasiAbsenEdit',
    name: 'usulanLokasiAbsenEdit',
    component: () => import('../../views/absensi/verifikasiLokasi/usulanLokasiAbsen/usulanLokasiAbsenEdit.vue')
  },
  {
    path: '/verifikasiLokasiAbsen',
    name: 'verifikasiLokasiAbsen',
    component: () => import('../../views/absensi/verifikasiLokasi/verifikasiLokasiAbsen/verifikasiLokasiAbsen.vue')
  },
  {
    path: '/verifikasiLokasiAbsenTerima',
    name: 'verifikasiLokasiAbsenTerima',
    component: () => import('../../views/absensi/verifikasiLokasi/verifikasiLokasiAbsen/verifikasiLokasiAbsenTerima.vue')
  },
  {
    path: '/verifikasiLokasiAbsenTolak',
    name: 'verifikasiLokasiAbsenTolak',
    component: () => import('../../views/absensi/verifikasiLokasi/verifikasiLokasiAbsen/verifikasiLokasiAbsenTolak.vue')
  },
  {
    path: '/verifikasiLokasiAbsenMaps',
    name: 'verifikasiLokasiAbsenMaps',
    component: () => import('../../views/absensi/verifikasiLokasi/verifikasiLokasiAbsen/verifikasiLokasiAbsenMaps.vue')
  },
  // END Verifikasi Lokasi


  // Pelaksanaan Apel
  {
    path: '/pelaksanaanApel',
    name: 'pelaksanaanApel',
    component: () => import('../../views/absensi/pelaksanaanApel/pelaksanaanApel.vue')
  },
  {
    path: '/jadwalPelaksanaan',
    name: 'jadwalPelaksanaan',
    component: () => import('../../views/absensi/pelaksanaanApel/jadwalPelaksanaan/jadwalPelaksanaan.vue')
  },
  {
    path: '/jadwalPelaksanaanAdd',
    name: 'jadwalPelaksanaanAdd',
    component: () => import('../../views/absensi/pelaksanaanApel/jadwalPelaksanaan/jadwalPelaksanaanAdd.vue')
  },
  {
    path: '/jadwalPelaksanaanEdit',
    name: 'jadwalPelaksanaanEdit',
    component: () => import('../../views/absensi/pelaksanaanApel/jadwalPelaksanaan/jadwalPelaksanaanEdit.vue')
  },
  {
    path: '/jadwalPelaksanaanMaps',
    name: 'jadwalPelaksanaanMaps',
    component: () => import('../../views/absensi/pelaksanaanApel/jadwalPelaksanaan/jadwalPelaksanaanMaps.vue')
  },
  {
    path: '/jenisApel',
    name: 'jenisApel',
    component: () => import('../../views/absensi/pelaksanaanApel/jenisApel/jenisApel.vue')
  },
  {
    path: '/jenisApelAdd',
    name: 'jenisApelAdd',
    component: () => import('../../views/absensi/pelaksanaanApel/jenisApel/jenisApelAdd.vue')
  },
  {
    path: '/jenisApelEdit',
    name: 'jenisApelEdit',
    component: () => import('../../views/absensi/pelaksanaanApel/jenisApel/jenisApelEdit.vue')
  },
  {
    path: '/jenisApelOpd',
    name: 'jenisApelOpd',
    component: () => import('../../views/absensi/pelaksanaanApel/jenisApel/jenisApelOpd.vue')
  },
  {
    path: '/jenisApelOpdAdd',
    name: 'jenisApelOpdAdd',
    component: () => import('../../views/absensi/pelaksanaanApel/jenisApel/jenisApelOpdAdd.vue')
  },



  // END Pelaksanaan Apel

  // ABSENSI APP
  {
    path: '/absensiApp',
    name: 'absensiApp',
    component: () => import('../../views/absensi/absensiApp.vue')
  },
  {
    path: '/absenKehadiran',
    name: 'absenKehadiran',
    component: () => import('../../views/absensi/absenKehadiran.vue')
  },
  {
    path: '/absenApel',
    name: 'absenApel',
    component: () => import('../../views/absensi/absenApel.vue')
  },
  {
    path: '/absenDarurat',
    name: 'absenDarurat',
    component: () => import('../../views/absensi/absenDarurat/absenDarurat.vue')
  },
  {
    path: '/absenDaruratAdd',
    name: 'absenDaruratAdd',
    component: () => import('../../views/absensi/absenDarurat/absenDaruratAdd.vue')
  },
  {
    path: '/absenDaruratLampiran',
    name: 'absenDaruratLampiran',
    component: () => import('../../views/absensi/absenDarurat/absenDaruratLampiran.vue')
  },
  {
    path: '/absenIzin',
    name: 'absenIzin',
    component: () => import('../../views/absensi/absenIzin/absenIzin.vue')
  },
  {
    path: '/absenIzinAdd',
    name: 'absenIzinAdd',
    component: () => import('../../views/absensi/absenIzin/absenIzinAdd.vue')
  },
  {
    path: '/absenIzinLampiran',
    name: 'absenIzinLampiran',
    component: () => import('../../views/absensi/absenIzin/absenIzinLampiran.vue')
  },
  {
    path: '/statistikAbsen',
    name: 'statistikAbsen',
    component: () => import('../../views/absensi/statistikAbsen/statistikAbsen.vue')
  },
  {
    path: '/statistikAbsenKehadiran',
    name: 'statistikAbsenKehadiran',
    component: () => import('../../views/absensi/statistikAbsen/statistikAbsenKehadiran.vue')
  },
  {
    path: '/statistikAbsenApel',
    name: 'statistikAbsenApel',
    component: () => import('../../views/absensi/statistikAbsen/statistikAbsenApel.vue')
  },
  {
    path: '/laporanApel',
    name: 'laporanApel',
    component: () => import('../../views/absensi/laporanApel.vue')
  },




  
  // END ABSENSI APP



]



export default routes
