
const routes = [
    {
      path: '/statistikSatpol',
      name: 'statistikSatpol',
      component: () => import('../../views/statistik/satpol/home.vue')
    },
    {
      path: '/statistikSatpolPs',
      name: 'statistikSatpolPs',
      component: () => import('../../views/statistik/satpol/pertahananSipil.vue')
    },
    
    
  ]
  
  
  
  export default routes
  