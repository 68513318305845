
import HomeView from '../../views/HomeView.vue'
import PortalView from '../../views/portal.vue'

import dkpView from '../../views/statistik/dkp.vue'

const routes = [
      {
        path: '/',
        name: 'home',
        component: PortalView
      },
      // {
        //   path: '/',
        //   name: 'home',
        //   component: () => import('../../views/portalGif')
        // },
      {
        path: '/dkpView',
        name: 'dkpView',
        component: dkpView
      },
     
      {
        path: '/login',
        name: 'login',
        component: () => import('../../views/auth/login.vue')
      },
      {
        path: '/portal',
        name: 'portal',
        component: () => import('../../views/portal')
      }
  ]
  
  
  
  export default routes
  