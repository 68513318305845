
const routes = [
    {
      path: '/statistikDinkes',
      name: 'statistikDinkes',
      component: () => import('../../views/statistik/dinkes/home.vue')
    },

      {
        path: '/statistikDinkesPenyakit',
        name: 'statistikDinkesPenyakit',
        component: () => import('../../views/statistik/dinkes/dinkesPenyakit.vue')
      },

      {
        path: '/statistikDinkesFaskes',
        name: 'statistikDinkesFaskes',
        component: () => import('../../views/statistik/dinkes/dinkesFaskes.vue')
      },

      {
        path: '/statistikDinkesNakes',
        name: 'statistikDinkesNakes',
        component: () => import('../../views/statistik/dinkes/dinkesNakes.vue')
      },

      {
        path: '/statistikDinkesMedis',
        name: 'statistikDinkesMedis',
        component: () => import('../../views/statistik/dinkes/dinkesMedis.vue')
      },

      {
        path: '/statistikDinkesBumil',
        name: 'statistikDinkesBumil',
        component: () => import('../../views/statistik/dinkes/dinkesBumil.vue')
      },

      {
        path: '/statistikDinkesBalita',
        name: 'statistikDinkesBalita',
        component: () => import('../../views/statistik/dinkes/dinkesBalita.vue')
      },

      {
        path: '/statistikDinkesBblr',
        name: 'statistikDinkesBblr',
        component: () => import('../../views/statistik/dinkes/dinkesBblr.vue')
      },

      {
        path: '/statistikDinkesTbc',
        name: 'statistikDinkesTbc',
        component: () => import('../../views/statistik/dinkes/dinkesTbc.vue')
      },

      {
        path: '/statistikDinkesDiare',
        name: 'statistikDinkesDiare',
        component: () => import('../../views/statistik/dinkes/dinkesDiare.vue')
      },

      {
        path: '/statistikDinkesGizi',
        name: 'statistikDinkesGizi',
        component: () => import('../../views/statistik/dinkes/dinkesGizi.vue')
      },
  
  ]
  
  
  
  export default routes
  