
const routes = [
    {
      path: '/beritaHome',
      name: 'beritaHome',
      component: () => import('../../views/berita/beritaHome.vue')
    },
    {
      path: '/beritaAdd',
      name: 'beritaAdd',
      component: () => import('../../views/berita/beritaAdd.vue')
    },
    {
      path: '/beritaEdit',
      name: 'beritaEdit',
      component: () => import('../../views/berita/beritaEdit.vue')
    },
    {
      path: '/beritaLihat',
      name: 'beritaLihat',
      component: () => import('../../views/berita/beritaLihat.vue')
    },
    {
      path: '/beritaTerima',
      name: 'beritaTerima',
      component: () => import('../../views/berita/beritaTerima.vue')
    },
    {
      path: '/beritaTolak',
      name: 'beritaTolak',
      component: () => import('../../views/berita/beritaTolak.vue')
    },
  
    
  ]
  
  
  
  export default routes
  