
const routes = [
    {
      path: '/statistikBkkbn',
      name: 'statistikBkkbn',
      component: () => import('../../views/statistik/bkkbn/home.vue')
    },
    {
      path: '/statistikBkkbnAkseptor',
      name: 'statistikBkkbnAkseptor',
      component: () => import('../../views/statistik/bkkbn/statistikBkkbnAkseptor.vue')
    },
    {
      path: '/statistikBkkbnKeluarga',
      name: 'statistikBkkbnKeluarga',
      component: () => import('../../views/statistik/bkkbn/statistikBkkbnKeluarga.vue')
    },
    {
      path: '/statistikBkkbnKlinik',
      name: 'statistikBkkbnKlinik',
      component: () => import('../../views/statistik/bkkbn/statistikBkkbnKlinik.vue')
    },
    {
      path: '/statistikBkkbnMetodeKontrasepsi',
      name: 'statistikBkkbnMetodeKontrasepsi',
      component: () => import('../../views/statistik/bkkbn/statistikBkkbnMetodeKontrasepsi.vue')
    },
    {
      path: '/statistikBkkbnPetugas',
      name: 'statistikBkkbnPetugas',
      component: () => import('../../views/statistik/bkkbn/statistikBkkbnPetugas.vue')
    },
    

   
  ]
  
  
  
  export default routes
  