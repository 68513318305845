
const routes = [
  {
    path: '/ppidHome',
    name: 'ppidHome',
    component: () => import('../../views/ppid/home.vue')
  },
  {
    path: '/ppidPesan_masuk',
    name: 'ppidPesan_masuk',
    component: () => import('../../views/ppid/pesan_masuk/pesan_masuk.vue')
  },
  {
    path: '/ppidPesan_masuk_balas/:id',
    name: 'ppidPesan_masuk_balas',
    component: () => import('../../views/ppid/pesan_masuk/pesan_masuk_balas.vue')
  },

  {
    path: '/ppidPesan_keluar',
    name: 'ppidPesan_keluar',
    component: () => import('../../views/ppid/pesan_keluar/pesan_keluar.vue')
  },
  {
    path: '/ppidPesan_keluar_balas/:id',
    name: 'ppidPesan_keluar_balas',
    component: () => import('../../views/ppid/pesan_keluar/pesan_keluar_balas.vue')
  },

  {
    path: '/ppidProfile',
    name: 'ppidProfile',
    component: () => import('../../views/ppid/profile/profile.vue')
  },
  {
    path: '/ppidProfile_view/:id',
    name: 'ppidProfile_view',
    component: () => import('../../views/ppid/profile/profile_view.vue')
  },
  



  {
    path: '/ppidInformasi_terbuka_berkala',
    name: 'ppidInformasi_terbuka_berkala',
    component: () => import('../../views/ppid/informasi_terbuka_berkala/informasi_terbuka_berkala.vue')
  },
    {
      path: '/ppid_tentang',
      name: 'ppid_tentang',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_tentang/ppid_tentang.vue')
    },
    {
      path: '/ppid_tentang_add',
      name: 'ppid_tentang_add',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_tentang/ppid_tentang_add.vue')
    },
    {
      path: '/ppid_tentang_edit',
      name: 'ppid_tentang_edit',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_tentang/ppid_tentang_edit.vue')
    },

    {
      path: '/ppid_laporan_kinerja',
      name: 'ppid_laporan_kinerja',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_laporan_kinerja/ppid_laporan_kinerja.vue')
    },
    {
      path: '/ppid_laporan_kinerja_add',
      name: 'ppid_laporan_kinerja_add',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_laporan_kinerja/ppid_laporan_kinerja_add.vue')
    },
    {
      path: '/ppid_laporan_kinerja_edit',
      name: 'ppid_laporan_kinerja_edit',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_laporan_kinerja/ppid_laporan_kinerja_edit.vue')
    },
    
    {
      path: '/ppid_perjanjian_kinerja',
      name: 'ppid_perjanjian_kinerja',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_perjanjian_kinerja/ppid_perjanjian_kinerja.vue')
    },
    {
      path: '/ppid_perjanjian_kinerja_add',
      name: 'ppid_perjanjian_kinerja_add',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_perjanjian_kinerja/ppid_perjanjian_kinerja_add.vue')
    },
    {
      path: '/ppid_perjanjian_kinerja_edit',
      name: 'ppid_perjanjian_kinerja_edit',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_perjanjian_kinerja/ppid_perjanjian_kinerja_edit.vue')
    },

    {
      path: '/ppid_rencana_kerja_tahunan',
      name: 'ppid_rencana_kerja_tahunan',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_rencana_kerja_tahunan/ppid_rencana_kerja_tahunan.vue')
    },
    {
      path: '/ppid_rencana_kerja_tahunan_add',
      name: 'ppid_rencana_kerja_tahunan_add',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_rencana_kerja_tahunan/ppid_rencana_kerja_tahunan_add.vue')
    },
    {
      path: '/ppid_rencana_kerja_tahunan_edit',
      name: 'ppid_rencana_kerja_tahunan_edit',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_rencana_kerja_tahunan/ppid_rencana_kerja_tahunan_edit.vue')
    },

    {
      path: '/ppid_indikator_kinerja_utama',
      name: 'ppid_indikator_kinerja_utama',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_indikator_kinerja_utama/ppid_indikator_kinerja_utama.vue')
    },
    {
      path: '/ppid_indikator_kinerja_utama_add',
      name: 'ppid_indikator_kinerja_utama_add',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_indikator_kinerja_utama/ppid_indikator_kinerja_utama_add.vue')
    },
    {
      path: '/ppid_indikator_kinerja_utama_edit',
      name: 'ppid_indikator_kinerja_utama_edit',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_indikator_kinerja_utama/ppid_indikator_kinerja_utama_edit.vue')
    },

    {
      path: '/ppid_rencana_strategis',
      name: 'ppid_rencana_strategis',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_rencana_strategis/ppid_rencana_strategis.vue')
    },
    {
      path: '/ppid_rencana_strategis_add',
      name: 'ppid_rencana_strategis_add',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_rencana_strategis/ppid_rencana_strategis_add.vue')
    },
    {
      path: '/ppid_rencana_strategis_edit',
      name: 'ppid_rencana_strategis_edit',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_rencana_strategis/ppid_rencana_strategis_edit.vue')
    },

    {
      path: '/ppid_rpjmd',
      name: 'ppid_rpjmd',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_rpjmd/ppid_rpjmd.vue')
    },
    {
      path: '/ppid_rpjmd_add',
      name: 'ppid_rpjmd_add',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_rpjmd/ppid_rpjmd_add.vue')
    },
    {
      path: '/ppid_rpjmd_edit',
      name: 'ppid_rpjmd_edit',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_rpjmd/ppid_rpjmd_edit.vue')
    },
    
    //====== LAMPIRAN PDF =======//
    {
      path: '/ppidLampiran',
      name: 'ppidLampiran',
      component: () => import('../../views/ppid/ppidLampiran.vue')
    },
    
    {
      path: '/ppid_itb_lihat/:id',
      name: 'ppid_itb_lihat',
      component: () => import('../../views/ppid/informasi_terbuka_berkala/ppid_itb_lihat.vue')
    },




    
  {
    path: '/ppidInformasi_terbuka_setiap_saat',
    name: 'ppidInformasi_terbuka_setiap_saat',
    component: () => import('../../views/ppid/informasi_terbuka_setiap_saat/informasi_terbuka_setiap_saat.vue')
  },
    {
      path: '/ppid_produk_hukum',
      name: 'ppid_produk_hukum',
      component: () => import('../../views/ppid/informasi_terbuka_setiap_saat/ppid_produk_hukum/ppid_produk_hukum.vue')
    },
    {
      path: '/ppid_produk_hukum_add',
      name: 'ppid_produk_hukum_add',
      component: () => import('../../views/ppid/informasi_terbuka_setiap_saat/ppid_produk_hukum/ppid_produk_hukum_add.vue')
    },
    {
      path: '/ppid_produk_hukum_edit',
      name: 'ppid_produk_hukum_edit',
      component: () => import('../../views/ppid/informasi_terbuka_setiap_saat/ppid_produk_hukum/ppid_produk_hukum_edit.vue')
    },
  
]



export default routes
