
const routes = [
  {
    path: '/jdihHome',
    name: 'jdihHome',
    component: () => import('../../views/jdih/home.vue')
  },
  {
    path: '/jdihSop',
    name: 'jdihSop',
    component: () => import('../../views/jdih/dokumen/sop/sop.vue')
  },
  {
    path: '/jdihSopAdd',
    name: 'jdihSopAdd',
    component: () => import('../../views/jdih/dokumen/sop/sopAdd.vue')
  },
  {
    path: '/jdihSopEdit',
    name: 'jdihSopEdit',
    component: () => import('../../views/jdih/dokumen/sop/sopEdit.vue')
  },
  {
    path: '/jdihSuratKeputusan',
    name: 'jdihSuratKeputusan',
    component: () => import('../../views/jdih/dokumen/suratKeputusan/suratKeputusan.vue')
  },
  {
    path: '/jdihSuratKeputusanAdd',
    name: 'jdihSuratKeputusanAdd',
    component: () => import('../../views/jdih/dokumen/suratKeputusan/suratKeputusanAdd.vue')
  },
  {
    path: '/jdihSuratKeputusanEdit',
    name: 'jdihSuratKeputusanEdit',
    component: () => import('../../views/jdih/dokumen/suratKeputusan/suratKeputusanEdit.vue')
  },
  {
    path: '/jdihPutusanPengadilan',
    name: 'jdihPutusanPengadilan',
    component: () => import('../../views/jdih/dokumen/putusanPengadilan/putusanPengadilan.vue')
  },
  {
    path: '/jdihPutusanPengadilanAdd',
    name: 'jdihPutusanPengadilanAdd',
    component: () => import('../../views/jdih/dokumen/putusanPengadilan/putusanPengadilanAdd.vue')
  },
  {
    path: '/jdihPutusanPengadilanEdit',
    name: 'jdihPutusanPengadilanEdit',
    component: () => import('../../views/jdih/dokumen/putusanPengadilan/putusanPengadilanEdit.vue')
  },

  {
    path: '/jdihPeraturanDaerah',
    name: 'jdihPeraturanDaerah',
    component: () => import('../../views/jdih/produkHukum/peraturanDaerah/peraturanDaerah.vue')
  },
  {
    path: '/jdihPeraturanDaerahAdd',
    name: 'jdihPeraturanDaerahAdd',
    component: () => import('../../views/jdih/produkHukum/peraturanDaerah/peraturanDaerahAdd.vue')
  },
  {
    path: '/jdihPeraturanDaerahEdit',
    name: 'jdihPeraturanDaerahEdit',
    component: () => import('../../views/jdih/produkHukum/peraturanDaerah/peraturanDaerahEdit.vue')
  },
  {
    path: '/jdihPeraturanBupati',
    name: 'jdihPeraturanBupati',
    component: () => import('../../views/jdih/produkHukum/peraturanBupati/peraturanBupati.vue')
  },
  {
    path: '/jdihPeraturanBupatiAdd',
    name: 'jdihPeraturanBupatiAdd',
    component: () => import('../../views/jdih/produkHukum/peraturanBupati/peraturanBupatiAdd.vue')
  },
  {
    path: '/jdihPeraturanBupatiEdit',
    name: 'jdihPeraturanBupatiEdit',
    component: () => import('../../views/jdih/produkHukum/peraturanBupati/peraturanBupatiEdit.vue')
  },
  {
    path: '/jdihKeputusanBupati',
    name: 'jdihKeputusanBupati',
    component: () => import('../../views/jdih/produkHukum/keputusanBupati/keputusanBupati.vue')
  },
  {
    path: '/jdihKeputusanBupatiAdd',
    name: 'jdihKeputusanBupatiAdd',
    component: () => import('../../views/jdih/produkHukum/keputusanBupati/keputusanBupatiAdd.vue')
  },
  {
    path: '/jdihKeputusanBupatiEdit',
    name: 'jdihKeputusanBupatiEdit',
    component: () => import('../../views/jdih/produkHukum/keputusanBupati/keputusanBupatiEdit.vue')
  },
  {
    path: '/jdihInstruksiBupati',
    name: 'jdihInstruksiBupati',
    component: () => import('../../views/jdih/produkHukum/instruksiBupati/instruksiBupati.vue')
  },
  {
    path: '/jdihInstruksiBupatiAdd',
    name: 'jdihInstruksiBupatiAdd',
    component: () => import('../../views/jdih/produkHukum/instruksiBupati/instruksiBupatiAdd.vue')
  },
  {
    path: '/jdihInstruksiBupatiEdit',
    name: 'jdihInstruksiBupatiEdit',
    component: () => import('../../views/jdih/produkHukum/instruksiBupati/instruksiBupatiEdit.vue')
  },
  {
    path: '/jdihKeputusanSkpd',
    name: 'jdihKeputusanSkpd',
    component: () => import('../../views/jdih/produkHukum/keputusanSkpd/keputusanSkpd.vue')
  },
  {
    path: '/jdihKeputusanSkpdAdd',
    name: 'jdihKeputusanSkpdAdd',
    component: () => import('../../views/jdih/produkHukum/keputusanSkpd/keputusanSkpdAdd.vue')
  },
  {
    path: '/jdihKeputusanSkpdEdit',
    name: 'jdihKeputusanSkpdEdit',
    component: () => import('../../views/jdih/produkHukum/keputusanSkpd/keputusanSkpdEdit.vue')
  },
  {
    path: '/jdihPemerintahDesa',
    name: 'jdihPemerintahDesa',
    component: () => import('../../views/jdih/produkHukum/pemerintahDesa/pemerintahDesa.vue')
  },
  {
    path: '/jdihPemerintahDesaAdd',
    name: 'jdihPemerintahDesaAdd',
    component: () => import('../../views/jdih/produkHukum/pemerintahDesa/pemerintahDesaAdd.vue')
  },
  {
    path: '/jdihPemerintahDesaEdit',
    name: 'jdihPemerintahDesaEdit',
    component: () => import('../../views/jdih/produkHukum/pemerintahDesa/pemerintahDesaEdit.vue')
  },

]



export default routes
