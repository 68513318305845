
const routes = [
  {
    path: '/simpegHome',
    name: 'simpegHome',
    component: () => import('../../views/simpeg/home.vue')
  },

  {
    path: '/simpegProfilEdit',
    name: 'simpegProfilEdit',
    component: () => import('../../views/simpeg/profilEdit.vue')
  },

  {
    path: '/simpegPengangkatan',
    name: 'simpegPengangkatan',
    component: () => import('../../views/simpeg/pengangkatan/pengangkatan.vue')
  },
  {
    path: '/simpegPengangkatanAdd',
    name: 'simpegPengangkatanAdd',
    component: () => import('../../views/simpeg/pengangkatan/pengangkatanAdd.vue')
  },
  {
    path: '/simpegPengangkatanEdit',
    name: 'simpegPengangkatanEdit',
    component: () => import('../../views/simpeg/pengangkatan/pengangkatanEdit.vue')
  },
  {
    path: '/simpegPengangkatanLampiran',
    name: 'simpegPengangkatanLampiran',
    component: () => import('../../views/simpeg/pengangkatan/pengangkatanLampiran.vue')
  },

  {
    path: '/simpegKepangkatan',
    name: 'simpegKepangkatan',
    component: () => import('../../views/simpeg/kepangkatan/kepangkatan.vue')
  },
  {
    path: '/simpegKepangkatanAdd',
    name: 'simpegKepangkatanAdd',
    component: () => import('../../views/simpeg/kepangkatan/kepangkatanAdd.vue')
  },
  {
    path: '/simpegKepangkatanEdit',
    name: 'simpegKepangkatanEdit',
    component: () => import('../../views/simpeg/kepangkatan/kepangkatanEdit.vue')
  },
  {
    path: '/simpegKepangkatanLampiran',
    name: 'simpegKepangkatanLampiran',
    component: () => import('../../views/simpeg/kepangkatan/kepangkatanLampiran.vue')
  },

  {
    path: '/simpegPendStruktural',
    name: 'simpegPendStruktural',
    component: () => import('../../views/simpeg/pendStruktural/pendStruktural.vue')
  },
  {
    path: '/simpegPendStrukturalAdd',
    name: 'simpegPendStrukturalAdd',
    component: () => import('../../views/simpeg/pendStruktural/pendStrukturalAdd.vue')
  },
  {
    path: '/simpegPendStrukturalEdit',
    name: 'simpegPendStrukturalEdit',
    component: () => import('../../views/simpeg/pendStruktural/pendStrukturalEdit.vue')
  },
  {
    path: '/simpegPendStrukturalLampiran',
    name: 'simpegPendStrukturalLampiran',
    component: () => import('../../views/simpeg/pendStruktural/pendStrukturalLampiran.vue')
  },

  {
    path: '/simpegPendFungsional',
    name: 'simpegPendFungsional',
    component: () => import('../../views/simpeg/pendFungsional/pendFungsional.vue')
  },
  {
    path: '/simpegPendFungsionalAdd',
    name: 'simpegPendFungsionalAdd',
    component: () => import('../../views/simpeg/pendFungsional/pendFungsionalAdd.vue')
  },
  {
    path: '/simpegPendFungsionalEdit',
    name: 'simpegPendFungsionalEdit',
    component: () => import('../../views/simpeg/pendFungsional/pendFungsionalEdit.vue')
  },
  {
    path: '/simpegPendFungsionalLampiran',
    name: 'simpegPendFungsionalLampiran',
    component: () => import('../../views/simpeg/pendFungsional/pendFungsionalLampiran.vue')
  },

  {
    path: '/simpegPendFormal',
    name: 'simpegPendFormal',
    component: () => import('../../views/simpeg/pendFormal/pendFormal.vue')
  },
  {
    path: '/simpegPendFormalAdd',
    name: 'simpegPendFormalAdd',
    component: () => import('../../views/simpeg/pendFormal/pendFormalAdd.vue')
  },
  {
    path: '/simpegPendFormalEdit',
    name: 'simpegPendFormalEdit',
    component: () => import('../../views/simpeg/pendFormal/pendFormalEdit.vue')
  },
  {
    path: '/simpegPendFormalLampiran',
    name: 'simpegPendFormalLampiran',
    component: () => import('../../views/simpeg/pendFormal/pendFormalLampiran.vue')
  },

  {
    path: '/simpegJabFungsional',
    name: 'simpegJabFungsional',
    component: () => import('../../views/simpeg/jabFungsional/jabFungsional.vue')
  },
  {
    path: '/simpegJabFungsionalAdd',
    name: 'simpegJabFungsionalAdd',
    component: () => import('../../views/simpeg/jabFungsional/jabFungsionalAdd.vue')
  },
  {
    path: '/simpegJabFungsionalEdit',
    name: 'simpegJabFungsionalEdit',
    component: () => import('../../views/simpeg/jabFungsional/jabFungsionalEdit.vue')
  },
  {
    path: '/simpegJabFungsionalLampiran',
    name: 'simpegJabFungsionalLampiran',
    component: () => import('../../views/simpeg/jabFungsional/jabFungsionalLampiran.vue')
  },

  {
    path: '/simpegJabStruktural',
    name: 'simpegJabStruktural',
    component: () => import('../../views/simpeg/jabStruktural/jabStruktural.vue')
  },
  {
    path: '/simpegJabStrukturalAdd',
    name: 'simpegJabStrukturalAdd',
    component: () => import('../../views/simpeg/jabStruktural/jabStrukturalAdd.vue')
  },
  {
    path: '/simpegJabStrukturalEdit',
    name: 'simpegJabStrukturalEdit',
    component: () => import('../../views/simpeg/jabStruktural/jabStrukturalEdit.vue')
  },
  {
    path: '/simpegJabStrukturalLampiran',
    name: 'simpegJabStrukturalLampiran',
    component: () => import('../../views/simpeg/jabStruktural/jabStrukturalLampiran.vue')
  },

  {
    path: '/simpegSuamiIstri',
    name: 'simpegSuamiIstri',
    component: () => import('../../views/simpeg/suamiIstri/suamiIstri.vue')
  },
  {
    path: '/simpegSuamiIstriAdd',
    name: 'simpegSuamiIstriAdd',
    component: () => import('../../views/simpeg/suamiIstri/suamiIstriAdd.vue')
  },
  {
    path: '/simpegSuamiIstriEdit',
    name: 'simpegSuamiIstriEdit',
    component: () => import('../../views/simpeg/suamiIstri/suamiIstriEdit.vue')
  },
  {
    path: '/simpegSuamiIstriLampiran',
    name: 'simpegSuamiIstriLampiran',
    component: () => import('../../views/simpeg/suamiIstri/suamiIstriLampiran.vue')
  },

  {
    path: '/simpegAnak',
    name: 'simpegAnak',
    component: () => import('../../views/simpeg/anak/anak.vue')
  },
  {
    path: '/simpegAnakAdd',
    name: 'simpegAnakAdd',
    component: () => import('../../views/simpeg/anak/anakAdd.vue')
  },
  {
    path: '/simpegAnakEdit',
    name: 'simpegAnakEdit',
    component: () => import('../../views/simpeg/anak/anakEdit.vue')
  },
  {
    path: '/simpegAnakLampiran',
    name: 'simpegAnakLampiran',
    component: () => import('../../views/simpeg/anak/anakLampiran.vue')
  },

]



export default routes
