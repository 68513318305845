
const routes = [
    {
      path: '/statistikDisperindag',
      name: 'statistikDisperindag',
      component: () => import('../../views/statistik/disperindag/home.vue')
    },
    {
      path: '/disperindagSarana',
      name: 'disperindagSarana',
      component: () => import('../../views/statistik/disperindag/disperindagSarana.vue')
    },
    {
      path: '/disperindagPertanian',
      name: 'disperindagPertanian',
      component: () => import('../../views/statistik/disperindag/disperindagPertanian.vue')
    },
    {
      path: '/disperindagPerlengkapan',
      name: 'disperindagPerlengkapan',
      component: () => import('../../views/statistik/disperindag/disperindagPerlengkapan.vue')
    },
    {
      path: '/disperindagPerkebunan',
      name: 'disperindagPerkebunan',
      component: () => import('../../views/statistik/disperindag/disperindagPerkebunan.vue')
    },
    {
      path: '/disperindagHutan',
      name: 'disperindagHutan',
      component: () => import('../../views/statistik/disperindag/disperindagHutan.vue')
    },
    {
      path: '/disperindagPerikanan',
      name: 'disperindagPerikanan',
      component: () => import('../../views/statistik/disperindag/disperindagPerikanan.vue')
    },
    {
      path: '/disperindagPeternakan',
      name: 'disperindagPeternakan',
      component: () => import('../../views/statistik/disperindag/disperindagPeternakan.vue')
    },
    {
      path: '/disperindagIndustri',
      name: 'disperindagIndustri',
      component: () => import('../../views/statistik/disperindag/disperindagIndustri.vue')
    },
    

   
  ]
  
  
  
  export default routes
  