
const routes = [
    {
      path: '/statistikDkp',
      name: 'statistikDkp',
      component: () => import('../../views/statistik/dkp/home.vue')
    },
    {
      path: '/dkpPerahu',
      name: 'dkpPerahu',
      component: () => import('../../views/statistik/dkp/perahuKapal/dkpPerahu.vue')
    },
    {
      path: '/dkpJenisPerahu',
      name: 'dkpJenisPerahu',
      component: () => import('../../views/statistik/dkp/perahuKapal/dkpJenisPerahu.vue')
    },
    {
      path: '/laut',
      name: 'laut',
      component: () => import('../../views/statistik/dkp/komoditas/laut.vue')
    },
    {
      path: '/umum',
      name: 'umum',
      component: () => import('../../views/statistik/dkp/komoditas/umum.vue')
    },
    {
      path: '/budidayaIkan',
      name: 'budidayaIkan',
      component: () => import('../../views/statistik/dkp/budidaya/budidayaIkan.vue')
    },
    {
      path: '/budidayaJenis',
      name: 'budidayaJenis',
      component: () => import('../../views/statistik/dkp/budidaya/budidayaJenis.vue')
    },
    {
      path: '/budidayaKegiatan',
      name: 'budidayaKegiatan',
      component: () => import('../../views/statistik/dkp/budidaya/budidayaKegiatan.vue')
    },

   
  ]
  
  
  
  export default routes
  