
const routes = [
    {
      path: '/statistikDprd',
      name: 'statistikDprd',
      component: () => import('../../views/statistik/dprd/home.vue')
    },
    {
      path: '/statistikDprdPartai',
      name: 'statistikDprdPartai',
      component: () => import('../../views/statistik/dprd/statistikDprdPartai.vue')
    },
    {
      path: '/statistikDprdKeputusan',
      name: 'statistikDprdKeputusan',
      component: () => import('../../views/statistik/dprd/statistikDprdKeputusan.vue')
    },
    {
      path: '/statistikDprdKegiatan',
      name: 'statistikDprdKegiatan',
      component: () => import('../../views/statistik/dprd/statistikDprdKegiatan.vue')
    },
    

   
  ]
  
  
  
  export default routes
  