<template>
    <div>

        <headerType1 namaApp="STATISTIK" routerx='/portal'/>

        
            <div class="row divContent" style="margin-top:36px;">
                
                <div class="iframe-container">
                    <iframe
                    class="fullscreen-iframe"
                    :src="iframeUrl"
                    frameborder="0"
                    allowfullscreen
                    @load="handleIframeLoad"
                    ></iframe>
                </div>
            
            </div>



    </div>
</template>

<script>

export default {
    name: 'statistikHome',
    data() {
        return {
            iframeUrl: "https://statistik-dkp.konaweselatankab.go.id/", 
        }
    },
    methods: {
        routex(route) {
            this.$store.state.halamanKini = this.$route.name;
            this.$router.push(route);
        },
        
    },

    mounted () {

        window.addEventListener("message", (event) => {


            // console.log("ajshdashdddddddddddddddddddd: ", event); // Nilai dari key1 di local storage parent
         
            // const data = JSON.parse(event.data);
            // console.log("ajshdashd : ", data); // Nilai dari key1 di local storage parent
            
            if (event.origin === "http://localhost:8080/") {
                // Lakukan sesuatu dengan data yang diterima dari parent
                const data = JSON.parse(event.data);

                console.log("ajshdashd : ", data); // Nilai dari key1 di local storage parent

                console.log(event.data.key1); // Nilai dari key1 di local storage parent
                console.log(event.data.key2); // Nilai dari key2 di local storage parent

                // localStorage.token = event.data.data.key1;
                // localStorage.profile = event.data.data.key2;
            }
        });

        
    },
}
</script>

<style scoped>
.iframe-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: white; /* Warna latar belakang sesuai kebutuhan */
}

.fullscreen-iframe {
  width: 100%;
  height: 100%;
}
</style>