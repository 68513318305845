
const routes = [
    {
      path: '/statistikHome',
      name: 'statistikHome',
      component: () => import('../../views/statistik/home.vue')
    },
    {
      path: '/statistikBapenda',
      name: 'statistikBapenda',
      component: () => import('../../views/statistik/bapenda/home.vue')
    },
    {
      path: '/statistikBapendaPajak',
      name: 'statistikBapendaPajak',
      component: () => import('../../views/statistik/bapenda/pajak/pajak.vue')
    },
    {
      path: '/statistikDputr',
      name: 'statistikDputr',
      component: () => import('../../views/statistik/dputr/home.vue')
    },
    {
      path: '/statistikDputrBinmar',
      name: 'statistikDputrBinmar',
      component: () => import('../../views/statistik/dputr/binmar/binmar.vue')
    },
    {
      path: '/statistikDputrCipkar',
      name: 'statistikDputrCipkar',
      component: () => import('../../views/statistik/dputr/cipkar/cipkar.vue')
    },
    {
      path: '/statistikDputrJaskon',
      name: 'statistikDputrJaskon',
      component: () => import('../../views/statistik/dputr/jaskon/jaskon.vue')
    },
    {
      path: '/statistikDputrSdair',
      name: 'statistikDputrSdair',
      component: () => import('../../views/statistik/dputr/sdair/sdair.vue')
    },
    {
      path: '/statistikDputrTataruang',
      name: 'statistikDputrTataruang',
      component: () => import('../../views/statistik/dputr/tataRuang/tataRuang.vue')
    },
    {
      path: '/statistikDputrJalan',
      name: 'statistikDputrJalan',
      component: () => import('../../views/statistik/dputr/jalan/jalan.vue')
    },
    {
      path: '/statistikDputrJembatan',
      name: 'statistikDputrJembatan',
      component: () => import('../../views/statistik/dputr/jembatan/jembatan.vue')
    },

 
  
  ]
  
  
  
  export default routes
  