
const routes = [
  {
    path: '/statistikDisnaker',
    name: 'statistikDisnaker',
    component: () => import('../../views/statistik/disnaker/home.vue')
  },

    {
      path: '/statistikDisnakerPekerja',
      name: 'statistikDisnakerPekerja',
      component: () => import('../../views/statistik/disnaker/disnakerPekerja.vue')
    },

    {
      path: '/statistikDisnakerStatus',
      name: 'statistikDisnakerStatus',
      component: () => import('../../views/statistik/disnaker/disnakerStatus.vue')
    },

    {
      path: '/statistikDisnakerTransmigran',
      name: 'statistikDisnakerTransmigran',
      component: () => import('../../views/statistik/disnaker/disnakerTransmigran.vue')
    }

]



export default routes
