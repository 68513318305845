
const routes = [
    {
      path: '/statistikDpmd',
      name: 'statistikDpmd',
      component: () => import('../../views/statistik/dpmd/home.vue')
    },

    {
      path: '/statistikDpmdBelanja',
      name: 'statistikDpmdBelanja',
      component: () => import('../../views/statistik/dpmd/dpmdBelanja.vue')
    },

      {
        path: '/statistikDpmdKades',
        name: 'statistikDpmdKades',
        component: () => import('../../views/statistik/dpmd/dpmdKades.vue')
      },

      {
        path: '/statistikDpmdLurah',
        name: 'statistikDpmdLurah',
        component: () => import('../../views/statistik/dpmd/dpmdLurah.vue')
      },

      {
        path: '/statistikDpmdPembangunan',
        name: 'statistikDpmdPembangunan',
        component: () => import('../../views/statistik/dpmd/dpmdPembangunan.vue')
      },

      {
        path: '/statistikDpmdPelaksanaan',
        name: 'statistikDpmdPelaksanaan',
        component: () => import('../../views/statistik/dpmd/dpmdPelaksanaan.vue')
      },

      {
        path: '/statistikDpmdPemerintahan',
        name: 'statistikDpmdPemerintahan',
        component: () => import('../../views/statistik/dpmd/dpmdPemerintahan.vue')
      },

      {
        path: '/statistikDpmdPemberdayaan',
        name: 'statistikDpmdPemberdayaan',
        component: () => import('../../views/statistik/dpmd/dpmdPemberdayaan.vue')
      },

      {
        path: '/statistikDpmdPembinaan',
        name: 'statistikDpmdPembinaan',
        component: () => import('../../views/statistik/dpmd/dpmdPembinaan.vue')
      },

      {
        path: '/statistikDpmdPendapatan',
        name: 'statistikDpmdPendapatan',
        component: () => import('../../views/statistik/dpmd/dpmdPendapatan.vue')
      },

      {
        path: '/statistikDpmdDarurat',
        name: 'statistikDpmdDarurat',
        component: () => import('../../views/statistik/dpmd/dpmdDarurat.vue')
      },
  
  ]
  
  
  
  export default routes
  