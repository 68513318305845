
const routes = [
    {
      path: '/statistikBpbd',
      name: 'statistikBpbd',
      component: () => import('../../views/statistik/bpbd/home.vue')
    },
    {
      path: '/bencanaalam',
      name: 'bencanaalam',
      component: () => import('../../views/statistik/bpbd/bencanaalam/bencanaalam.vue')
    },
    

   
  ]
  
  
  
  export default routes
  